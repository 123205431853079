<template>
  <div>
    <v-row class="pa-0 ma-0 mx-2">
      <v-col cols="12">
        <overview />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Overview from "./Widgets/Overview.vue";

export default {
  name: "Activities",
  components: { Overview },
  data() {
    return {};
  },
};
</script>
