<template>
  <v-card
    class="border-radius-l mt-5"
    :class="$route.name == 'VrDefault' ? 'bg-gradient-default' : ''"
  >
    <v-row class="justify-space-between pt-3 pb-5 px-7">
      <div>
        <h5 class="text-h5 my-2 font-weight-bold text-typo">Übersicht</h5>
      </div>
    </v-row>
    <v-divider></v-divider>
    <v-card-text class="px-0 py-0">
      <v-data-table
        :headers="headers"
        :items="[]"
        :search="search"
        class="table"
        :page.sync="page"
        hide-default-footer
        @page-count="pageCount = $event"
        :items-per-page="itemsPerPage"
        mobile-breakpoint="0"
      >
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "Overview",
  data() {
    return {
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      dialog: false,
      dialogDelete: false,
      users,
      search: "",
      editedIndex: -1,
      editedItem: {
        name: "",
        email: "",
        age: "",
        salary: "",
      },
      defaultItem: {
        name: "",
        email: "",
        age: "",
        salary: "",
      },
      headers: [
        {
          text: "Name",
          align: "start",
          cellClass: "border-bottom",
          sortable: false,
          value: "name",
          class:
            "text-secondary font-weight-bolder opacity-7 border-bottom ps-6",
        },
        {
          text: "Email",
          value: "email",
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: "Age",
          value: "age",
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: "Salary",
          value: "salary",
          class: "text-secondary font-weight-bolder opacity-7",
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          class: "text-secondary font-weight-bolder opacity-7",
        },
      ],
    };
  },
  methods: {
    editItem(item) {
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.users.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.users[this.editedIndex], this.editedItem);
      } else {
        this.users.push(this.editedItem);
      }
      this.close();
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
    pages() {
      return this.pagination.rowsPerPage
        ? Math.ceil(this.items.length / this.pagination.rowsPerPage)
        : 0;
    },
  },
};
</script>

<style>
</style>